import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/c6c823337da0b0f018db0539b6c91cac/aa440/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__f480cc4b-2010-452c-9ba1-fa058c305e7a.png",
            "srcSet": ["/static/c6c823337da0b0f018db0539b6c91cac/5ff7e/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__f480cc4b-2010-452c-9ba1-fa058c305e7a.png 375w", "/static/c6c823337da0b0f018db0539b6c91cac/1d69c/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__f480cc4b-2010-452c-9ba1-fa058c305e7a.png 750w", "/static/c6c823337da0b0f018db0539b6c91cac/aa440/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__f480cc4b-2010-452c-9ba1-fa058c305e7a.png 1500w", "/static/c6c823337da0b0f018db0539b6c91cac/71c1d/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__f480cc4b-2010-452c-9ba1-fa058c305e7a.png 1536w"],
            "width": "600px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`The Central District of Meripol`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Meripol is the largest city in `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum`}</a>{`, as well as its capitol. The city is built onto a series of plateaus along the southern coast of Aljieudum, each of the city’s districts being housed on a different shelf. Inside the canyons between the plateau’s there are a number of villages and towns. There is also an elevator on the southern plateau that leads to a `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` outpost attached to Meripol, a sort of trading center, embassy, and welcome center for relations between the overlanders and the Msanti.`}</p>
    <h2>{`Geography`}</h2>
    <p>{`Meripol's layout is largely driven by its geography. The entirety of the city proper is built atop a series of plateaus along the coastline, with a number of interconnected bridges, both for walking and for `}<a parentName="p" {...{
        "href": "/Grav-rail",
        "title": "Grav-rail"
      }}>{`Grav-rail`}</a>{`. For those that can afford it, some citizens of Meripol also make use of `}<a parentName="p" {...{
        "href": "/Aerostat",
        "title": "Aerostat"
      }}>{`Aerostats`}</a>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/92bd3521ba633c7decc4afb8abb127b6/aa440/Morne_Massive_portal_made_of_a_gothic_architecture_circular_str_2cb57f20-1dcb-479a-9913-303ea71143a4.png",
            "srcSet": ["/static/92bd3521ba633c7decc4afb8abb127b6/5ff7e/Morne_Massive_portal_made_of_a_gothic_architecture_circular_str_2cb57f20-1dcb-479a-9913-303ea71143a4.png 375w", "/static/92bd3521ba633c7decc4afb8abb127b6/1d69c/Morne_Massive_portal_made_of_a_gothic_architecture_circular_str_2cb57f20-1dcb-479a-9913-303ea71143a4.png 750w", "/static/92bd3521ba633c7decc4afb8abb127b6/aa440/Morne_Massive_portal_made_of_a_gothic_architecture_circular_str_2cb57f20-1dcb-479a-9913-303ea71143a4.png 1500w", "/static/92bd3521ba633c7decc4afb8abb127b6/71c1d/Morne_Massive_portal_made_of_a_gothic_architecture_circular_str_2cb57f20-1dcb-479a-9913-303ea71143a4.png 1536w"],
            "width": "400px",
            "className": "center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Meripol's Prismagate`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <h2>{`The Prismagate`}</h2>
    <p>{`As Aljieudum's capitol city, Meripol has a highly trafficked `}<a parentName="p" {...{
        "href": "/Prismagates",
        "title": "Prismagates"
      }}>{`Prismagate`}</a>{` that is used by travelers to visit the city from all over the world, though primarily nations in `}<a parentName="p" {...{
        "href": "/Edelun",
        "title": "Edelun"
      }}>{`Edelun`}</a>{` and `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{`. This is the primary method of transportation for commercial trade and government officials from `}<a parentName="p" {...{
        "href": "/Tharsis",
        "title": "Tharsis"
      }}>{`Tharsis`}</a>{` and other countries along the northwestern coast of `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{`.`}</p>
    <h2>{`Economy`}</h2>
    <p>{`Meripol is a thriving economic hub in the country of `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum`}</a>{`, with a diverse range of industries driving its prosperity. The city's strategic location on the southern coast of the kingdom has long made it a hub for trade and commerce, with merchant ships docking in its bustling port to unload their goods and take on new cargo.`}</p>
    <p>{`One of the city's main industries is fishing, with the waters off the southern coast being home to a rich array of sea life. Meripol's fish markets are known throughout Aljiuedum for their fresh, high-quality catches, and the city's seafood restaurants are a popular destination for tourists and locals alike.`}</p>
    <p>{`In addition to its maritime industries, Meripol is also home to a number of artisans and craftsmen who produce a variety of goods, from pottery and jewelry to furniture and clothing. The city's many markets and shopping districts are a treasure trove of unique, locally-made products, and its artisanal traditions are an important part of its cultural identity.`}</p>
    <p>{`Another major contributor to the city's economy is tourism, with Meripol's beautiful coastal setting and rich cultural heritage attracting visitors from all over the kingdom and beyond. The city's many hotels, inns, and bed and breakfasts are always busy, and its many attractions, such as its historic landmarks, museums, and cultural festivals, such as ones celebrating `}<a parentName="p" {...{
        "href": "/Illuminaris",
        "title": "Illuminaris"
      }}>{`Illuminaris`}</a>{`, draw large crowds of tourists.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/419a01dabd702bfc59cb6e742825d3a8/aa440/Morne_Close_up_view_of_a_train_on_a_bridge_moving_through_a_fan_69e6b7d4-d275-43cd-b5b1-667d810ba074.png",
            "srcSet": ["/static/419a01dabd702bfc59cb6e742825d3a8/5ff7e/Morne_Close_up_view_of_a_train_on_a_bridge_moving_through_a_fan_69e6b7d4-d275-43cd-b5b1-667d810ba074.png 375w", "/static/419a01dabd702bfc59cb6e742825d3a8/1d69c/Morne_Close_up_view_of_a_train_on_a_bridge_moving_through_a_fan_69e6b7d4-d275-43cd-b5b1-667d810ba074.png 750w", "/static/419a01dabd702bfc59cb6e742825d3a8/aa440/Morne_Close_up_view_of_a_train_on_a_bridge_moving_through_a_fan_69e6b7d4-d275-43cd-b5b1-667d810ba074.png 1500w", "/static/419a01dabd702bfc59cb6e742825d3a8/71c1d/Morne_Close_up_view_of_a_train_on_a_bridge_moving_through_a_fan_69e6b7d4-d275-43cd-b5b1-667d810ba074.png 1536w"],
            "width": "450px",
            "className": "left center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Grav-train passing through Meripol`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Being the location of `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia Omnia`}</a>{`, Meripol also thrives with its constant influx of `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` graduating from the university and entering various industries, such as travel (`}<a parentName="p" {...{
        "href": "/Grav-rail",
        "title": "Grav-rail"
      }}>{`Grav-rail`}</a>{`, Aerostats, `}<a parentName="p" {...{
        "href": "/Prismagates",
        "title": "Prismagates"
      }}>{`Prismagates`}</a>{`), Energy (`}<a parentName="p" {...{
        "href": "/Acronist#furybearers",
        "title": "Acronist"
      }}>{`Furybearers`}</a>{` powering the cities electrical systems), and more.`}</p>
    <p>{`Finally, the Msanti outpost located on the southern plateau plays a significant role in the city's economy, serving as a hub for trade and diplomacy between the overlanders and the `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{`. The outpost is home to a number of merchants who specialize in the unique goods produced by the Msanti, such as their advanced technological devices, and it serves as a gateway for cultural exchange between the two groups.`}</p>
    <p>{`Overall, the economy of Meripol is diverse and dynamic, with a wide range of industries contributing to its prosperity and growth.`}</p>
    <h2>{`Government`}</h2>
    <p>{`Meripol is the capitol city of `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum`}</a>{` and as such, plays a central role in the nation's political landscape. The city's government is made up of two main bodies: the `}<a parentName="p" {...{
        "href": "/Chamber%20of%20Nobles",
        "title": "Chamber of Nobles"
      }}>{`Chamber of Nobles`}</a>{` and the `}<a parentName="p" {...{
        "href": "/Assembly%20of%20Vassals",
        "title": "Assembly of Vassals"
      }}>{`Assembly of Vassals`}</a>{`. The Chamber of Nobles is made up of various noble families and aristocrats from across the kingdom, who hold hereditary positions and wield significant political influence. The Assembly of Vassals, on the other hand, is made up of elected officials who represent the city's various districts and neighborhoods.`}</p>
    <p>{`Both the Chamber of Nobles and the Assembly of Vassals play important roles in the city's decision-making process, and they often work together to pass legislation and set policies. However, there are also times when they are at odds, with the Chamber of Nobles representing the interests of the aristocracy and the Assembly of Vassals representing the interests of the common people.`}</p>
    <p>{`In addition to these two main bodies, there is also  `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`The Argent`}</a>{`, a powerful religious organization that wields significant political influence in Meripol and throughout Aljiuedum. The Argent is made up of a council of high-ranking clergy who advise the city's leaders on matters of faith and morals regarding `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`, and they also play a role in enforcing the city's laws and maintaining order.`}</p>
    <p>{`Other important positions in the city's government include the Mayor of Meripol, who is responsible for overseeing the day-to-day operations of the city and implementing the policies set by the Chamber of Nobles and the Assembly of Vassals, and the Chief Justice, who presides over the city's courts and the city's law enforcement, the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{`, and is responsible for interpreting and enforcing the law. There are also a number of other officials and departments responsible for various aspects of the city's administration, such as finance, public works, and security.`}</p>
    <p>{`Overall, the politics of Meripol are complex and multifaceted, with a variety of actors and interests at play. The city's leaders must navigate the competing demands of its various constituents in order to govern effectively and ensure the well-being of its citizens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      